import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const Banner = ({ item }) => {
  return (
    <div className="col-12">
      <div
        className="shop-default-banner shop-boxed-banner banner d-flex align-items-center mb-6 br-xs"
        style={{
          backgroundImage: `url(${item.image})`,
        }}
      >
        <div className="banner-content">
          <h4 className="banner-subtitle font-weight-bold">
            {item.acSubtitle}
          </h4>
          <h3 className="banner-title text-white text-uppercase font-weight-bolder ls-10">
            {item.acTitle}
          </h3>
          <Link
            to={item.acLink}
            className="btn btn-dark btn-rounded btn-icon-right"
          >
            {item.acButton}
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
