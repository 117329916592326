import React from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const IntroHome = ({ bannerPosition6, bannerPosition7 }) => {
  const { dataSliderListAll } = useSelector((state) => state.globalReducer);

  return (
    <div className="intro-wrapper">
      <div className="row">
        <div className="col-md-8 mb-4">
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            navigation
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            scrollbar={{ draggable: true }}
          >
            {dataSliderListAll?.map((item, key) => (
              <SwiperSlide key={key}>
                <div className="swiper-wrapper row gutter-no cols-1">
                  <Link to={item.acLink}>
                    <div
                      className="swiper-slide banner banner-fixed intro-slide intro-slide3 br-sm"
                      style={{
                        backgroundImage: `url(${item.image})`,
                      }}
                    >
                      <div className="banner-content">
                        <div className="slide-animate1">
                          <h3 className="banner-title text-capitalize ls-25">
                            {item.acTitle}
                          </h3>
                          <div className="banner-price-info text-uppercase text-default mb-3 ls-25">
                            {item.acSubtitle}
                          </div>
                          {item.acButton && (
                            <button
                              to={item.acLink}
                              className="btn btn-white btn-rounded btn-icon-right"
                            >
                              {item.acButton}
                              <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="col-md-4">
          <div className="row">
            {bannerPosition6.length > 0 && (
              <div className="col-md-12 col-sm-6 mb-4">
                <Link to={bannerPosition6[0].acLink}>
                  <div className="intro-banner banner banner-fixed overlay-light overlay-zoom br-sm">
                    <figure>
                      <img
                        src={bannerPosition6[0].image}
                        alt={bannerPosition6[0].acTitle}
                        width="347"
                        height="245"
                        style={{ backgroundColor: "#1b1612" }}
                      />
                    </figure>
                  </div>
                </Link>
              </div>
            )}
            {bannerPosition7.length > 0 && (
              <div className="col-md-12 col-sm-6 mb-4">
                <Link to={bannerPosition7[0].acLink}>
                  <div className="intro-banner banner banner-fixed overlay-dark overlay-zoom br-sm">
                    <figure>
                      <img
                        src={bannerPosition7[0].image}
                        alt={bannerPosition7[0].acTitle}
                        width="347"
                        height="245"
                        style={{ backgroundColor: "#1b1612" }}
                      />
                    </figure>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroHome;
