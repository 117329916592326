import {
  faBriefcase,
  faChevronRight,
  faHeadset,
  faMoneyBill,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AsideProductItem from "./AsideProductItem";
import {
  fetchBannersList,
  fetchProductsCategoriesTypeAll,
} from "../actions/global";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const AsideHome = () => {
  const dispatch = useDispatch();
  const [isClassAdded, setIsClassAdded] = useState(false);
  const {
    dataProductsPopularnoList,
    dataBannerListAll,
    dataProductsPonovoNaStanjuList,
  } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    const type2 = "popularno";
    const type6 = "ponovoNaStanju";

    dispatch(fetchProductsCategoriesTypeAll(type2));
    dispatch(fetchProductsCategoriesTypeAll(type6));
    dispatch(fetchBannersList());
  }, [dispatch]);

  useEffect(() => {
    if (isClassAdded) {
      document.body.classList.add("sidebar-active");
    } else {
      document.body.classList.remove("sidebar-active");
    }
  }, [isClassAdded]);

  const toggleClass = () => {
    setIsClassAdded((prevState) => !prevState);
  };

  const bannerPosition = dataBannerListAll?.filter(
    (item) => item.anPosition == 1
  );

  return (
    <aside className="sidebar sticky-sidebar-wrapper sidebar-fixed">
      <div className="sidebar-overlay">
        <a className="sidebar-close" onClick={toggleClass}>
          <i className="close-icon"></i>
        </a>
      </div>
      <a className="sidebar-toggle-left-menu" onClick={toggleClass}>
        <FontAwesomeIcon icon={faChevronRight} />
      </a>
      <div className="sidebar-content scrollable sidebar-info-left-mobile">
        <a className="sidebar-close" onClick={toggleClass}>
          <i className="close-icon"></i>
        </a>
        <div className="sticky-sidebar">
          {bannerPosition.length > 0 && (
            <Link to={bannerPosition[0].acLink}>
              <div className="banner banner-fixed banner-sale br-sm mb-9 appear-animate">
                <figure>
                  <img
                    src={bannerPosition[0].image}
                    alt="Banner"
                    width="280"
                    height="388"
                    style={{ backgroundColor: "#202020" }}
                  />
                </figure>
                <div className="banner-content x-50 y-50 w-100 text-center">
                  <h5 className="banner-subtitle text-white font-weight-bold text-capitalize">
                    {bannerPosition[0].acSubtitle}
                  </h5>
                  <h3 className="banner-title text-uppercase text-white mb-0 lh-1 ls-50">
                    {bannerPosition[0].acTitle}
                  </h3>
                  {bannerPosition[0].acButton && (
                    <Link
                      to={bannerPosition[0].acLink}
                      className="btn btn-white btn-outline btn-rounded mt-6"
                    >
                      {bannerPosition[0].acButton}
                    </Link>
                  )}
                </div>
              </div>
            </Link>
          )}
          {/* <!-- End of Banner --> */}

          <div className="widget widget-products mb-5 appear-animate">
            <div className="title-link-wrapper mb-2">
              <h4 className="title title-link title-sm font-weight-bold pt-0 pb-1">
                Popularno
              </h4>
            </div>
            <div className="swiper">
              <div className="swiper-container swiper-theme nav-top">
                <div className="swiper-wrapper">
                  <div className="swiper-slide widget-col">
                    {dataProductsPopularnoList?.slice(0, 5).map((item, key) => (
                      <AsideProductItem item={item} key={key} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Widget Products --> */}

          <div className="widget widget-icon-box mb-9 appear-animate">
            <div className="icon-box icon-box-side">
              <span className="icon-box-icon text-primary">
                <FontAwesomeIcon
                  icon={faTruck}
                  style={{ fontSize: "2.4rem" }}
                />
              </span>
              <div className="icon-box-content">
                <h4 className="icon-box-title mb-1">Besplatna isporuka</h4>
                <p>
                  Za kupovinu preko <b>5.000,00 dinara</b>
                </p>
              </div>
            </div>
            {/* <div className="icon-box icon-box-side">
              <span className="icon-box-icon text-primary">
                <FontAwesomeIcon
                  icon={faBriefcase}
                  style={{ fontSize: "2.4rem" }}
                />
              </span>
              <div className="icon-box-content">
                <h4 className="icon-box-title mb-1">Secure Payment</h4>
                <p>We ensure secure payment</p>
              </div>
            </div> */}
            {/* <div className="icon-box icon-box-side">
              <span className="icon-box-icon text-primary">
                <FontAwesomeIcon
                  icon={faMoneyBill}
                  style={{ fontSize: "2.4rem" }}
                />
              </span>
              <div className="icon-box-content">
                <h4 className="icon-box-title mb-1">Money Back Guarantee</h4>
                <p>Any back within 30 days</p>
              </div>
            </div> */}
            {/* <div className="icon-box icon-box-side">
              <span className="icon-box-icon text-primary">
                <FontAwesomeIcon
                  icon={faHeadset}
                  style={{ fontSize: "2.4rem" }}
                />
              </span>
              <div className="icon-box-content">
                <h4 className="icon-box-title mb-1">Customer Support</h4>
                <p>Call or email us 24/7</p>
              </div>
            </div> */}
          </div>
          {/* <!-- End of Widget Icon Box --> */}

          <div className="contact-options-list">
            <div class="title-link-wrapper mb-2">
              <h4 class="title title-link title-sm font-weight-bold pt-0 pb-1">
                Pišite nam putem
              </h4>
            </div>
            <img
              src="/images/whatsapp.png"
              className="img-fluid icon-social-contant-icons"
            />
            <img
              src="/images/viber.png"
              className="img-fluid icon-social-contant-icons"
            />
            <img
              src="/images/sms.png"
              className="img-fluid icon-social-contant-icons"
            />
          </div>
          <a href="/images/uslovi-placanja.jpg" target="_blank">
            <img src="/images/uslovi-placanja.jpg" className="img-fluid" />
          </a>
          <div className="widget widget-products mb-5 appear-animate">
            <div className="title-link-wrapper mb-2">
              <h4 className="title title-link title-sm font-weight-bold pt-0 pb-1">
                Najprodavanije
              </h4>
            </div>
            <div className="swiper">
              <div className="swiper-container swiper-theme nav-top">
                <div className="swiper-wrapper">
                  <div className="swiper-slide widget-col">
                    {dataProductsPonovoNaStanjuList
                      ?.slice(0, 5)
                      .map((item, key) => (
                        <AsideProductItem item={item} key={key} />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Widget Products --> */}
        </div>
      </div>
      {/* <!-- End of Sidebar-content --> */}
    </aside>
  );
};

export default AsideHome;
