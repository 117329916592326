import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { fetchSearchProductsAll } from "../actions/global";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListProductsItem from "../components/ListProductsItem";
import HelmetSeo from "../components/Reusable/HelmetSeo";

const SearchList = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const text = params.get("pojam");
  const [orderCatalog, setOrderCatalog] = useState(
    localStorage.getItem("orderCatalog") || "asc"
  );

  const dispatch = useDispatch();

  const { dataSearchProductsList, loadingSearch } = useSelector(
    (state) => state.globalReducer
  );
  //prvo ucitavanje
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  useEffect(() => {
    const dataValue = {
      text: text,
      order: orderCatalog,
    };
    dispatch(fetchSearchProductsAll(dataValue));
  }, [dispatch, text, orderCatalog]);

  const handleSortChange = (e) => {
    const newOrder = e.target.value;
    setOrderCatalog(newOrder); // Postavljamo novo sortiranje u stanje
    localStorage.setItem("orderCatalog", newOrder); // Sačuvajte novo sortiranje u lokalnom skladištu
  };

  return (
    <>
      <HelmetSeo
        title={`${text} | UP-IT Shop`}
        description={``}
        keywords={``}
      />
      <nav className="breadcrumb-nav">
        <div className="container1">
          <ul className="breadcrumb bb-no">
            <li>
              <Link to="/">Pocetna -</Link>
            </li>
            <li>
              <a>Pretraga -</a>
            </li>
            <li>{text}</li>
          </ul>
        </div>
      </nav>
      <div className="page-content">
        <div className="container1">
          <div className="shop-content row gutter-lg mb-10">
            <div className="main-content1">
              {loadingSearch ? (
                <div className="loader"></div>
              ) : (
                <>
                  <nav className="toolbox sticky-toolbox sticky-content fix-top">
                    <div className="toolbox-left">
                      <div className="serach-box-item-content-text">
                        Rezultat pretrage za: <b>"{text}"</b>{" "}
                        <span>({dataSearchProductsList.length} proizvoda)</span>
                      </div>
                    </div>
                    <div className="toolbox-right">
                      <div className="toolbox-item toolbox-sort select-box text-dark">
                        <label>Sortiraj po :</label>
                        <select
                          name="orderby"
                          className="form-control"
                          value={orderCatalog}
                          style={{ width: "150px" }}
                          onChange={(e) => handleSortChange(e)}
                        >
                          <option value="desc">Cena opadajuće</option>
                          <option value="asc">Cena rastuće</option>
                        </select>
                      </div>
                    </div>
                  </nav>
                  <div className="product-wrapper row cols-lg-4 cols-md-3 cols-sm-2 cols-1">
                    {dataSearchProductsList.length > 0 ? (
                      <>
                        {dataSearchProductsList?.map((item, key) => (
                          <div className="product-wrap" key={key}>
                            <ListProductsItem item={item} />
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="col-12">
                        <div className="alert alert-icon alert-primary alert-bg alert-inline show-code-action">
                          <h4 className="alert-title">
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </h4>{" "}
                          Tražene proizvode trenutno nemamo u ponudi.
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchList;
