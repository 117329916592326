import "./App.css";

import { Route, Routes } from "react-router-dom";
// import { locationPath } from "./constants/appRoutes";
// import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import store from "./reducers";
import { securityActions } from "./reducers/security";

import AuthContext from "./context/auth_context";

//Route
import SecuredRoute from "./utils/security/SecuredRoute";

//pages site
import Home from "./pages/Home";
import Catalog from "./pages/Catalog";
import ProductDetail from "./pages/ProductDetail";
import Contact from "./pages/Contact";
import CategoryList from "./pages/CategoryList";
import SearchList from "./pages/SearchList";
import ErrorPage from "./pages/ErrorPage";
import MesecnaAkcija from "./pages/MesecnaAkcija";
import Preporucujemo from "./pages/Preporucujemo";
import Popularno from "./pages/Popularno";
import IzdvajamoIzPonude from "./pages/IzdvajamoIzPonude";
import PonovoNaStanju from "./pages/PonovoNaStanju";
import SpecijalnaAkcija from "./pages/SpecijalnaAkcija";

// import "./assets/animate.min.css";

//css
import "./assets/global.scss";
import "./assets/responsive.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style.min.css";
import "./assets/demo6.min.css";
import { Helmet } from "react-helmet-async";

const phpToken = localStorage.getItem("phpToken");
const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

if (phpToken) {
  let items = {
    validToken: phpToken,
    loggedUser,
  };
  store.dispatch(securityActions.setCurrentUser(items));
}

function App() {
  const loggedUserReducer = useSelector((state) => state.loggedUserReducer);

  return (
    <AuthContext.Provider
      value={{
        ...loggedUserReducer,
      }}
    >
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-R6Y8E5YWC1"
        ></script>
        <script async>
          {`
            window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-R6Y8E5YWC1');
          `}
        </script>
      </Helmet>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <SecuredRoute>
              <Home />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/katalog/:key/:value"
          element={
            <SecuredRoute>
              <Catalog />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/proizvod/:naziv/:sifra"
          element={
            <SecuredRoute>
              <ProductDetail />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/kontakt"
          element={
            <SecuredRoute>
              <Contact />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/kategorije"
          element={
            <SecuredRoute>
              <CategoryList />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/pretraga"
          element={
            <SecuredRoute>
              <SearchList />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/mesecna-akcija"
          element={
            <SecuredRoute>
              <MesecnaAkcija />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/izdvajamo-iz-ponude"
          element={
            <SecuredRoute>
              <IzdvajamoIzPonude />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/preporucujemo"
          element={
            <SecuredRoute>
              <Preporucujemo />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/popularno"
          element={
            <SecuredRoute>
              <Popularno />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/najprodavanije"
          element={
            <SecuredRoute>
              <PonovoNaStanju />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/specijalna-akcija"
          element={
            <SecuredRoute>
              <SpecijalnaAkcija />
            </SecuredRoute>
          }
        />
        <Route
          path="*"
          element={
            <SecuredRoute>
              <ErrorPage />
            </SecuredRoute>
          }
        />
      </Routes>
      <ToastContainer />
    </AuthContext.Provider>
  );
}

export default App;
