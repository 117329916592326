import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="error-page-container">
      <h1>404 Greška</h1>
      <p>Stranica koju tražite nije pronađena.</p>
      <Link to="/">
        <button className="btn btn-primary btn-rounded">
          Vratite se na početnu stranu
        </button>
      </Link>
    </div>
  );
};

export default ErrorPage;
