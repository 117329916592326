import { Link } from "react-router-dom";
import React from "react";

const CategoryBox = ({ item }) => {
  return (
    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-4">
      <div className="category category-group-image br-sm">
        <div className="category-content">
          <h4 className="category-name category-name-custom-page">
            <Link to={`/katalog/kategorija/${item.id}?page=1`}>
              {item.name ? item.name : "OSTALO"}
            </Link>
          </h4>
          <ul className="category-list">
            {item.children?.map((item2, key) => (
              <li key={key}>
                <Link to={`/katalog/nadgrupa/${item2.id}?page=1`}>
                  {item2.name}
                </Link>
                {/* Treca dubina kategorija */}
                <ul className="category-list-sub">
                  {item2.children?.map((item3, key) => (
                    <li key={key}>
                      <Link to={`/katalog/grupa/${item3.id}?page=1`}>
                        {item3.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
        {/* <a >
          <figure className="category-media">
            <img
              src="/images/2-4.jpg"
              alt="Category"
              width="600"
              height="675"
            />
          </figure>
        </a> */}
      </div>
    </div>
  );
};

export default CategoryBox;
