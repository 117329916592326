import dot from "dot-object";

export const baseUrl = "https://api.up-it.rs/";

export const appendObjectInFormData = (formData, data, name) => {
  formData.append(
    name,
    new Blob([JSON.stringify(data)], {
      type: "application/json",
    })
  );
};

export const basicFormValidationHandler = (objectForValidation) => {
  const errorTranslations = "obavezno polje!";
  const dotNotationObj = dot(objectForValidation);
  let errorsObject = {};
  Object.entries(dotNotationObj?.separator)?.forEach((entry) => {
    let fieldValue = typeof entry[1] === "string" ? entry[1]?.trim() : entry[1];

    if (typeof entry[1] === "boolean") {
      fieldValue = entry[1].toString();
    }
    if (
      !fieldValue ||
      fieldValue === "" ||
      fieldValue?.length === 0 ||
      fieldValue === 0 ||
      fieldValue === "0"
    ) {
      errorsObject[`${entry[0]}`] = errorTranslations;
    }
  });

  return errorsObject;
};

export const basicFormValidationHandler2 = (objectForValidation) => {
  const errorTranslations = "obavezno polje!";
  const dotNotationObj = dot(objectForValidation);
  let errorsObject = {};
  Object.entries(dotNotationObj?.separator)?.forEach((entry) => {
    let fieldValue = typeof entry[1] === "string" ? entry[1]?.trim() : entry[1];

    if (typeof entry[1] === "boolean") {
      fieldValue = entry[1].toString();
    }
    if (!fieldValue || fieldValue === "" || fieldValue?.length === 0) {
      errorsObject[`${entry[0]}`] = errorTranslations;
    }
  });

  return errorsObject;
};

export const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Dodaje nulu ispred ako je jednocifren mesec
  const day = String(now.getDate()).padStart(2, "0"); // Dodaje nulu ispred ako je jednocifren dan
  return `${year}-${month}-${day}`;
};

export const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:00`;
};

export const isValidEmail = (email) => {
  // Regularni izraz za validaciju email adrese
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  return emailRegex.test(email);
};

export const formatDate = (dateString) => {
  // Pretvorite datum iz stringa u JavaScript Date objekat
  const date = new Date(dateString);

  // Postavite opcije za formatiranje datuma
  const options = {
    year: "numeric",
    month: "long", // "long" znači da će biti pun naziv meseca
    day: "numeric",
  };

  // Koristite toLocaleDateString za formatiranje datuma
  return date.toLocaleDateString(undefined, options);
};
