import React, { useEffect, useState } from "react";

import CategoryBox from "../components/CategoryBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetSeo from "../components/Reusable/HelmetSeo";
import { Link } from "react-router-dom";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const CategoryList = () => {
  const { dateCategoriesList } = useSelector((state) => state.globalReducer);
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function removeDiacritics(text) {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  // Blocks koji će biti filtrirani na osnovu unosa
  const filteredBlocks = dateCategoriesList?.filter(
    (block) =>
      removeDiacritics(block.name.toLowerCase()).includes(
        removeDiacritics(searchTerm.toLowerCase())
      ) ||
      block.children?.some((grupa) =>
        removeDiacritics(grupa.name.toLowerCase()).includes(
          removeDiacritics(searchTerm.toLowerCase())
        )
      )
  );

  //prvo ucitavanje
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <>
      <HelmetSeo
        title={`Kategorije | UP-IT Shop`}
        description={``}
        keywords={``}
      />
      <nav className="breadcrumb-nav container1">
        <ul className="breadcrumb bb-no">
          <li>
            <Link to="/">Pocetna -</Link>
          </li>
          <li>
            <a>Kategorije -</a>
          </li>
          <li>Pregled svih kategorija</li>
        </ul>
      </nav>
      <div className="top-categories-wrapper appear-animate mb-8">
        <div className="input-category-search-list">
          <form className="header-search hs-expanded hs-round bg-white br-xs d-md-flex input-wrapper mr-4 ml-4">
            <input
              type="text"
              placeholder="Pronađi željenu kategoriju ..."
              value={searchTerm}
              className="form-control text-light border-no"
              onChange={handleInputChange}
            />
            <button className="btn btn-search border-no" type="submit">
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
        </div>
        <div className="row">
          {filteredBlocks?.map((item, key) => (
            <CategoryBox item={item} key={key} />
          ))}
        </div>
      </div>
    </>
  );
};

export default CategoryList;
