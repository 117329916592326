import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Navigation, Pagination } from "swiper/modules";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  faArrowRightLong,
  faEnvelope,
  faMagnifyingGlass,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  fetchProductsDetail,
  fetchRelatedProductsAll,
} from "../actions/global";

import AsideHome from "../components/AsideHome";
import Captions from "yet-another-react-lightbox/plugins/captions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import HelmetSeo from "../components/Reusable/HelmetSeo";
import Lightbox from "yet-another-react-lightbox";
import ListProductsItem from "../components/ListProductsItem";
import Loader from "../components/Reusable/Loader";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// Import Swiper React components

// Import Swiper styles

const popover = (
  <Popover id="popover-basic">
    <Popover.Body>
      <div>062/63-97-96</div>
      <div>062/20-86-84</div>
    </Popover.Body>
  </Popover>
);
const popoverMail = (
  <Popover id="popover-basic">
    <Popover.Body>
      <div>up.it.webshop@gmail.com</div>
    </Popover.Body>
  </Popover>
);

const ProductDetail = () => {
  const { sifra } = useParams();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [closeOnBackdropClick, setCloseOnBackdropClick] = useState(true);

  const {
    dataProductDetailList,
    dataRelatedProductsList,
    loadingProductDetail,
  } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    const dataValue = {
      sifra: sifra,
    };
    dispatch(fetchProductsDetail(dataValue));
  }, [sifra, dispatch]);

  const formattedPrice = (price) => {
    const parts = price.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  };

  const [mainImage, setMainImage] = useState("");

  //prvo ucitavanje
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    if (dataProductDetailList && dataProductDetailList.length > 0) {
      // Proverite da li postoje podaci o proizvodima i da li postoji barem jedna slika za prvi proizvod
      const firstProduct = dataProductDetailList[0];
      if (firstProduct.slike && firstProduct.slike.length > 0) {
        setMainImage(firstProduct.slike[0]);
      } else {
        setMainImage("/images/no-image-product.png");
      }
    }
  }, [dataProductDetailList]);

  useEffect(() => {
    if (dataProductDetailList.length > 0) {
      const dataValue = {
        tip: "grupa",
        grupa: dataProductDetailList[0].grupa,
        limit: 6,
        random: 1,
      };
      dispatch(fetchRelatedProductsAll(dataValue));
    }
  }, [dataProductDetailList, dispatch]);

  return (
    <>
      <HelmetSeo
        title={`${
          dataProductDetailList.length > 0 && dataProductDetailList[0].naziv
        } | UP-IT Shop`}
        description={``}
        keywords={``}
      />

      {loadingProductDetail ? (
        <Loader />
      ) : (
        <>
          {dataProductDetailList && dataProductDetailList.length > 0 && (
            <>
              <nav className="breadcrumb-nav container1">
                <ul className="breadcrumb bb-no">
                  <li>
                    <Link to="/">Pocetna -</Link>
                  </li>
                  <li>
                    <Link
                      to={`/katalog/nadgrupa/${dataProductDetailList[0].nadgrupa_id}/`}
                    >
                      {dataProductDetailList[0].nadgrupa} -
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/katalog/grupa/${dataProductDetailList[0].grupa_id}/`}
                    >
                      {dataProductDetailList[0].grupa} -
                    </Link>
                  </li>
                  <li>
                    <b>{dataProductDetailList[0].naziv}</b>
                  </li>
                </ul>
              </nav>

              <div className="page-content product-detail-page-content">
                <div className="container1">
                  <div className="row gutter-lg">
                    <div className="main-content">
                      <div className="product product-single row">
                        <div className="col-md-6 mb-6">
                          <div className="product-gallery product-gallery-sticky">
                            <div className="swiper-container product-single-swiper swiper-theme nav-inner">
                              <div className="swiper-wrapper row cols-1 gutter-no">
                                <div className="swiper-slide2">
                                  <figure
                                    className="product-image product-image-custom-produc-detail"
                                    onClick={() => setOpen(true)}
                                  >
                                    <div className="zoom-image-icon-product-detail">
                                      <FontAwesomeIcon
                                        icon={faMagnifyingGlass}
                                        type="button"
                                      />
                                    </div>
                                    <img
                                      src={mainImage}
                                      data-zoom-image="https://www.uspon.rs/images/products/big/90821.webp"
                                      alt="UP IT"
                                      width="800"
                                      height="900"
                                    />
                                    <Lightbox
                                      open={open}
                                      close={() => setOpen(false)}
                                      slides={dataProductDetailList[0].slike.map(
                                        (slika) => ({ src: slika })
                                      )}
                                      index={dataProductDetailList[0].slike.indexOf(
                                        mainImage
                                      )}
                                      styles={{
                                        container: {
                                          backgroundColor: "rgba(0, 0, 0, .8)",
                                        },
                                      }}
                                      controller={{
                                        closeOnBackdropClick,
                                      }}
                                      plugins={[
                                        Captions,
                                        Fullscreen,
                                        Slideshow,
                                        Thumbnails,
                                        Video,
                                        Zoom,
                                      ]}
                                    />

                                    <div className="product-label-group">
                                      {dataProductDetailList[0].new == 1 && (
                                        <label className="product-label label-new">
                                          Novo
                                        </label>
                                      )}

                                      {dataProductDetailList[0].izdvojeno ==
                                        1 && (
                                        <label className="product-label label-new">
                                          Izdvojeno
                                        </label>
                                      )}
                                      {dataProductDetailList[0].popularno ==
                                        1 && (
                                        <label className="product-label label-hot">
                                          Popularno
                                        </label>
                                      )}
                                      {dataProductDetailList[0]
                                        .mesecna_akcija == 1 && (
                                        <label className="product-label label-discount">
                                          % Mesečna akcija
                                        </label>
                                      )}
                                      {dataProductDetailList[0].akcija == 1 && (
                                        <label className="product-label label-discount">
                                          % Akcija
                                        </label>
                                      )}
                                      {dataProductDetailList[0]
                                        .ponovo_na_stanju == 1 && (
                                        <label className="product-label label-hot">
                                          Najprodavanije
                                        </label>
                                      )}
                                      {dataProductDetailList[0].preporucujemo ==
                                        1 && (
                                        <label className="product-label label-hot">
                                          Preporučujemo
                                        </label>
                                      )}
                                      {parseFloat(
                                        dataProductDetailList[0]
                                          .cena_sa_popustom
                                      ) <
                                        parseFloat(
                                          dataProductDetailList[0].cena
                                        ) && (
                                        <label className="product-label label-discount">
                                          % NA POPUSTU
                                        </label>
                                      )}
                                    </div>
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="product-thumbs-wrap swiper-container">
                              <div className="product-thumbs swiper-wrapper row gutter-sm">
                                <Swiper
                                  loop={true}
                                  modules={[Navigation, Pagination]}
                                  spaceBetween={30}
                                  slidesPerView={3}
                                  navigation
                                  pagination={{ clickable: true }}
                                  scrollbar={{ draggable: true }}
                                >
                                  {dataProductDetailList[0].slike.map(
                                    (item, key) => (
                                      <SwiperSlide key={key}>
                                        <div
                                          key={key}
                                          className="product-thumb product-thumb-custom-product-detail"
                                          onClick={(e) => setMainImage(item)}
                                        >
                                          <img src={item} alt="Product Thumb" />
                                        </div>
                                      </SwiperSlide>
                                    )
                                  )}
                                </Swiper>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 sticky-sidebar-wrapper mb-2 mb-md-2">
                          <div
                            className="product-details sticky-sidebar"
                            data-sticky-options="{'minWidth': 767}"
                          >
                            <h1 className="product-title product-detail-title-custom">
                              {dataProductDetailList[0].naziv}
                            </h1>
                            <div className="product-bm-wrapper">
                              {/* <figure className="brand">
                        <img
                          src="assets/images/products/brand/brand-2.jpg"
                          alt="Brand"
                          width="105"
                          height="48"
                        />
                      </figure> */}
                              <div className="product-meta">
                                <ul className="shipping-helper">
                                  <li className="ok">
                                    <span itemprop="availability">
                                      Na stanju
                                    </span>
                                  </li>
                                </ul>
                                <div className="product-categories product-categories-detail-custom">
                                  <b>Brend</b>:{" "}
                                  <span>
                                    {dataProductDetailList[0].proizvodjac}
                                  </span>
                                </div>
                                <div className="product-categories product-categories-detail-custom">
                                  <b>Šifra artikla</b>:{" "}
                                  <span>{dataProductDetailList[0].sifra}</span>
                                </div>
                                <div className="product-categories product-categories-detail-custom">
                                  <b>Nadgrupa</b>:{" "}
                                  <span>
                                    {dataProductDetailList[0].nadgrupa}
                                  </span>
                                </div>
                                <div className="product-categories product-categories-detail-custom">
                                  <b>Grupa</b>:{" "}
                                  <span>{dataProductDetailList[0].grupa}</span>
                                </div>
                                <div className="product-categories product-categories-detail-custom">
                                  <b>Lager</b>:{" "}
                                  <span>
                                    {dataProductDetailList[0].kolicina}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="product-price product-price-holder-custom product-detail-price-custom">
                              {dataProductDetailList[0].cena_sa_popustom ? (
                                parseFloat(
                                  dataProductDetailList[0].cena_sa_popustom
                                ) <
                                parseFloat(dataProductDetailList[0].cena) ? (
                                  <>
                                    <div className="old-price-custom-div">
                                      <del>
                                        {formattedPrice(
                                          dataProductDetailList[0].cena
                                        )}{" "}
                                        RSD
                                      </del>
                                    </div>
                                    <ins className="new-price new-price-custom">
                                      {formattedPrice(
                                        dataProductDetailList[0]
                                          .cena_sa_popustom
                                      )}{" "}
                                      <small>RSD</small>
                                    </ins>
                                  </>
                                ) : (
                                  <>
                                    <small className="small-title-price-item">
                                      Cena za gotovinsko:
                                    </small>
                                    <ins className="new-price new-price-custom">
                                      {formattedPrice(
                                        dataProductDetailList[0].cena_gotovinsko
                                      )}{" "}
                                      <small>RSD</small>
                                    </ins>
                                    <div className="payment-price-custom">
                                      Odloženo plaćanje:{" "}
                                      {formattedPrice(
                                        dataProductDetailList[0]
                                          .cena_sa_popustom
                                      )}{" "}
                                      <small>RSD</small>
                                    </div>
                                  </>
                                )
                              ) : parseFloat(
                                  dataProductDetailList[0].cena_sa_popustom
                                ) <
                                parseFloat(dataProductDetailList[0].cena) ? (
                                <>
                                  <div className="old-price-custom-div">
                                    <del>
                                      {formattedPrice(
                                        dataProductDetailList[0].cena
                                      )}{" "}
                                      RSD
                                    </del>
                                  </div>
                                  <ins className="new-price new-price-custom">
                                    {formattedPrice(
                                      dataProductDetailList[0].cena_sa_popustom
                                    )}{" "}
                                    <small>RSD</small>
                                  </ins>
                                </>
                              ) : (
                                <>
                                  {dataProductDetailList[0].cena > 1000 ? (
                                    <>
                                      <small className="small-title-price-item">
                                        Cena za gotovinsko:
                                      </small>
                                      <ins className="new-price new-price-custom">
                                        {formattedPrice(
                                          dataProductDetailList[0]
                                            .cena_gotovinsko
                                        )}{" "}
                                        <small>RSD</small>
                                      </ins>
                                    </>
                                  ) : (
                                    <>
                                      <small className="small-title-price-item">
                                        Cena za gotovinsko:
                                      </small>
                                      <ins className="new-price new-price-custom">
                                        {formattedPrice(
                                          dataProductDetailList[0].cena
                                        )}{" "}
                                        <small>RSD</small>
                                      </ins>
                                    </>
                                  )}
                                  {dataProductDetailList[0].cena > 1000 && (
                                    <div className="payment-price-custom">
                                      Odloženo plaćanje:{" "}
                                      {formattedPrice(
                                        dataProductDetailList[0].cena
                                      )}{" "}
                                      <small>RSD</small>
                                    </div>
                                  )}
                                </>
                              )}

                              {/* {dataProductDetailList[0].cena_sa_popustom &&
                                parseFloat(
                                  dataProductDetailList[0].cena_sa_popustom
                                ) <
                                  parseFloat(dataProductDetailList[0].cena) && (
                                  <div className="old-price-custom-div">
                                    <del>
                                      {formattedPrice(
                                        dataProductDetailList[0].cena
                                      )}{" "}
                                      RSD
                                    </del>
                                  </div>
                                )} */}
                              {/* <ins className="new-price new-price-custom">
                                {dataProductDetailList[0].cena_sa_popustom
                                  ? formattedPrice(
                                      dataProductDetailList[0].cena_sa_popustom
                                    )
                                  : formattedPrice(
                                      dataProductDetailList[0].cena
                                    )}{" "}
                                <small>RSD</small>
                              </ins> */}
                              {/* {(dataProductDetailList[0].cena_sa_popustom ===
                                null ||
                                parseFloat(
                                  dataProductDetailList[0].cena_sa_popustom
                                ) >
                                  parseFloat(
                                    dataProductDetailList[0].cena
                                  )) && (
                                <div className="payment-price-custom">
                                  Cena gotovisnko:{" "}
                                  {formattedPrice(
                                    dataProductDetailList[0].cena_gotovinsko
                                  )}{" "}
                                  <small>RSD</small>
                                </div>
                              )} */}
                            </div>

                            <div className="fix-bottom product-sticky-content sticky-content">
                              <div className="product-form container">
                                {/* <div className="product-qty-form">
                                  <div className="input-group">
                                    <input
                                      className="quantity form-control"
                                      type="number"
                                      value={dataProductDetailList[0].kolicina}
                                      readOnly
                                    />
                                  </div>
                                </div> */}
                                <div className="btn-item-call-action btn-item-call-action-mobile">
                                  <a
                                    href="tel:+38162208684"
                                    className="btn btn-primary btn-cart w-100"
                                  >
                                    <FontAwesomeIcon icon={faPhone} />
                                    <span>Pozovite za upit</span>
                                  </a>
                                </div>
                                <div className="btn-item-call-action btn-item-call-action-2 btn-item-call-action-mobile">
                                  <a
                                    href="mailto:up.it.webshop@gmail.com"
                                    className="btn btn-primary btn-cart w-100"
                                  >
                                    <FontAwesomeIcon icon={faEnvelope} />
                                    <span>Pišite nam</span>
                                  </a>
                                </div>
                                <div className="btn-item-call-action btn-item-call-action-desktop">
                                  <OverlayTrigger
                                    trigger="click"
                                    placement="bottom"
                                    overlay={popover}
                                  >
                                    <Button className="btn btn-primary btn-cart w-100">
                                      <FontAwesomeIcon icon={faPhone} />
                                      <span>Pozovite za upit</span>
                                    </Button>
                                  </OverlayTrigger>
                                  {/* <button className="btn btn-primary btn-cart w-100">
                                    <FontAwesomeIcon icon={faPhone} />
                                    <span>Pozovite za upit</span>
                                  </button> */}
                                </div>
                                <div className="btn-item-call-action btn-item-call-action-2 btn-item-call-action-desktop">
                                  <OverlayTrigger
                                    trigger="click"
                                    placement="bottom"
                                    overlay={popoverMail}
                                  >
                                    <Button className="btn btn-primary btn-cart w-100">
                                      <FontAwesomeIcon icon={faEnvelope} />
                                      <span>Pišite nam</span>
                                    </Button>
                                  </OverlayTrigger>
                                  {/* <button className="btn btn-primary btn-cart w-100">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                    <span>Pišite nam</span>
                                  </button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Tabs
                        defaultActiveKey="specifikacija"
                        id="uncontrolled-tab-example"
                      >
                        <Tab eventKey="specifikacija" title="Specifikacija">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: dataProductDetailList[0].opis.replace(
                                /<br\s*\/?>/g,
                                ""
                              ),
                            }}
                          />
                        </Tab>
                        <Tab eventKey="deklaracija" title="Deklaracija">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                dataProductDetailList[0].deklaracija.replace(
                                  /<br\s*\/?>/g,
                                  ""
                                ),
                            }}
                          />
                        </Tab>
                      </Tabs>

                      <section className="related-product-section">
                        <div className="title-link-wrapper mb-4">
                          <h4 className="title">Povezani proizvodi</h4>
                          <Link
                            to={`/katalog/grupa/${dataProductDetailList[0].grupa}?page=1`}
                            className="btn btn-dark btn-link btn-slide-right btn-icon-right"
                          >
                            Pogledajte sve proizvode{" "}
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </Link>
                        </div>
                        <div className="product-wrapper row cols-md-3 cols-sm-2 cols-1">
                          {dataRelatedProductsList &&
                            dataRelatedProductsList?.map((item, key) => {
                              return (
                                <div className="product-wrap" key={key}>
                                  <ListProductsItem item={item} />
                                </div>
                              );
                            })}
                        </div>
                      </section>
                    </div>
                    <AsideHome />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProductDetail;
