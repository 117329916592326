import React, { useEffect, useRef, useState } from "react";
import {
  faEnvelope,
  faHeadphones,
  faMapMarked,
} from "@fortawesome/free-solid-svg-icons";

import CustomModal from "../components/Common/Modal/CustomModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetSeo from "../components/Reusable/HelmetSeo";
import ReCAPTCHA from "react-google-recaptcha";
import { fetchContactFormSent } from "../actions/global";
import { isValidEmail } from "../utils";
import { useDispatch } from "react-redux";
import { useModalToggle } from "../hooks/useModalToggle";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  // const key = "6LeUftEoAAAAAGV2wD_uDEKaIrwHMZFw7sKDgkC9"; //localhost
  const key = "6LecVckqAAAAAKFAioAc4EYvDSsq3qK7woayVauH"; //up-it.shop.rs

  const initialState = {
    acName: "",
    acEmail: "",
    acMessage: "",
    acToken: "",
  };

  const initialStateErrors = {
    acName: "",
    acEmail: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState(initialStateErrors);
  const recaptchaRef = useRef();

  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, toggleModal] = useModalToggle(false);

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    if (recaptchaRef?.current) {
      const token = await recaptchaRef?.current.executeAsync();
      setFormData((prevData) => ({ ...prevData, acToken: token }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validacija
    const newErrors = {};
    if (!formData.acName) {
      newErrors.acName = "Obavezno polje";
    }
    if (!formData.acEmail) {
      newErrors.acEmail = "Obavezno polje";
    }
    if (!formData.acMessage) {
      newErrors.acMessage = "Obavezno polje";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      if (isValid === false) {
        setErrors({ acEmail: "Obavezno polje" });
        // setErrorMessage("greska 2");
        // setShowMessage(false);
        // setShowMessageError(true);
        setTimeout(() => {
          // setShowMessageError(false);
        }, 3000);
      } else {
        if (formData.acToken)
          dispatch(
            fetchContactFormSent(formData, showMessageContact, navigate)
          );
      }
    } else {
      // setErrorMessage("greska1");
      // setShowMessage(false);
      // setShowMessageError(true);
      setTimeout(() => {
        // setShowMessageError(false);
      }, 3000);
    }
  };

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const handleInputChangeEmail = (event) => {
    const { name, value } = event.target;
    setIsValid(isValidEmail(value));

    setFormData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const modalCloseHandler = () => {
    toggleModal(false);
  };

  const showMessageContact = () => {
    // setShowMessage(true);
    toggleModal();
    // setShowMessageError(false);

    setTimeout(() => {
      toggleModal();
    }, 60000);

    setFormData({
      acName: "",
      acSubject: "",
      acEmail: "",
      acMessage: "",
    });
    getToken();
    setErrors(initialStateErrors);
  };

  //prvo ucitavanje
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <>
      <HelmetSeo
        title={`Kontakt | UP-IT Shop`}
        description={``}
        keywords={``}
      />
      <nav className="breadcrumb-nav mb-10 pb-1">
        <div className="container"></div>
      </nav>

      <div className="page-content contact-us">
        <div className="container">
          <section className="content-title-section mb-10">
            <h3 className="title title-center mb-3">Kontakt informacije</h3>
            <p className="text-center">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut
            </p>
          </section>

          <section className="contact-information-section mb-10">
            <div className=" swiper-container swiper-theme ">
              <div className="swiper-wrapper row cols-xl-4 cols-md-3 cols-sm-2 cols-1">
                <div className="swiper-slide icon-box text-center icon-box-primary">
                  <span className="icon-box-icon icon-email">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ fontSize: "30px", color: "#ff6206" }}
                    />
                  </span>
                  <div className="icon-box-content">
                    <h4 className="icon-box-title">E-mail Servis</h4>
                    <p>
                      <a
                        href="mailto:up.it.servis@gmail.com"
                        className="__cf_email__"
                        data-cfemail="701d11191c301508111d001c155e131f1d"
                      >
                        up.it.servis@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="swiper-slide icon-box text-center icon-box-primary">
                  <span className="icon-box-icon icon-email">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ fontSize: "30px", color: "#ff6206" }}
                    />
                  </span>
                  <div className="icon-box-content">
                    <h4 className="icon-box-title">E-mail za porudzbine</h4>
                    <p>
                      <a
                        href="mailto:up.it.webshop@gmail.com"
                        className="__cf_email__"
                        data-cfemail="701d11191c301508111d001c155e131f1d"
                      >
                        up.it.webshop@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="swiper-slide icon-box text-center icon-box-primary">
                  <span className="icon-box-icon icon-headphone">
                    <FontAwesomeIcon
                      icon={faHeadphones}
                      style={{ fontSize: "30px", color: "#ff6206" }}
                    />
                  </span>
                  <div className="icon-box-content">
                    <h4 className="icon-box-title">Telefon</h4>
                    <p>
                      <a
                        style={{ fontSize: "18px" }}
                        href="tel:+38162208684"
                        className="phone-number  ls-50"
                      >
                        062/20-86-84
                      </a>
                      <br />
                      <a
                        style={{ fontSize: "18px" }}
                        href="tel:+38162639796"
                        className="phone-number  ls-50"
                      >
                        062/63-97-96
                      </a>
                    </p>
                  </div>
                </div>
                <div className="swiper-slide icon-box text-center icon-box-primary">
                  <span className="icon-box-icon icon-map-marker">
                    <FontAwesomeIcon
                      icon={faMapMarked}
                      style={{ fontSize: "30px", color: "#ff6206" }}
                    />
                  </span>
                  <div className="icon-box-content">
                    <h4 className="icon-box-title">Adresa</h4>
                    <p>Kralja Petra I 177/5 Mladenovac, Srbija</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr className="divider mb-10 pb-1"></hr>
          <section className="contact-section">
            <div className="row gutter-lg pb-3">
              <div className="col-lg-6 mb-8">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d356.07661900447704!2d20.692786059381852!3d44.441101076162994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4750b1423a1cf071%3A0xe9070911e84d9bda!2s&#39;&#39;UP-IT&#39;&#39;%20Servis%20i%20prodaja%20Desktop%20i%20laptop%20ra%C4%8Dunara%2C%20ra%C4%8Dunarske%20mre%C5%BEe!5e0!3m2!1sen!2srs!4v1711134374374!5m2!1sen!2srs"
                  width="100%"
                  height="450"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="col-lg-6 mb-8">
                <h4 className="title mb-3">Kontaktirajte nas</h4>
                <form onSubmit={handleSubmit} className="form contact-us-form">
                  <ReCAPTCHA
                    sitekey={key}
                    size="invisible"
                    ref={recaptchaRef}
                  />
                  <div className="form-group">
                    <label htmlFor="username">Vaše ime i prezime</label>
                    <input
                      className={`form-control ${
                        errors.acName && "has-error-input"
                      }`}
                      type="text"
                      name="acName"
                      value={formData.acName}
                      placeholder={"Unesite vaše ime i prezime"}
                      onChange={handleInputChange}
                    />
                    {/* <input
                      type="text"
                      id="username"
                      name="username"
                      className="form-control"
                    /> */}
                  </div>
                  <div className="form-group">
                    <label htmlFor="email_1">Vaš email</label>
                    <input
                      className={`form-control ${
                        errors.acEmail && "has-error-input"
                      }`}
                      type="text"
                      name="acEmail"
                      value={formData.acEmail}
                      placeholder={"Unesite vašu email adresu"}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Vaša poruka</label>
                    <textarea
                      className={`form-control ${
                        errors.acMessage && "has-error-input"
                      }`}
                      name="acMessage"
                      placeholder={"Unesite vašu poruku"}
                      value={formData.acMessage}
                      onChange={handleInputChange}
                      rows="6"
                    />
                  </div>
                  <button type="submit" className="btn btn-dark btn-rounded">
                    Kontaktirajte nas
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
      {showModal && (
        <CustomModal
          backdrop={false}
          centered={true}
          footerContent={false}
          modalTitle={"Uspešno poslata poruka"}
          show={showModal}
          hideModal={modalCloseHandler}
        >
          <div>
            Hvala! Uspešno ste popunili kontakt formu. Uskoro će Vam se neko
            javiti.
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default Contact;
