import React, { useEffect, useRef, useState } from "react";
import { faArrowRight, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import CustomModal from "../../components/Common/Modal/CustomModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { fetchNewsletterFormSent } from "../../actions/global";
import { useDispatch } from "react-redux";
import { useModalToggle } from "../../hooks/useModalToggle";

const Footer = () => {
  // const key = "6LeUftEoAAAAAGV2wD_uDEKaIrwHMZFw7sKDgkC9"; //localhost
  const key = "6LecVckqAAAAAKFAioAc4EYvDSsq3qK7woayVauH"; //up-it.shop.rs

  const [cookieAccepted, setCookieAccepted] = useState(false);

  const initialState = {
    acEmail: "",
  };

  const initialStateErrors = {
    acEmail: "",
  };

  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(initialStateErrors);
  const [showModal, toggleModal] = useModalToggle(false);
  const [errorMessage, setErrorMessage] = useState("");
  const recaptchaRef = useRef();
  const [showMessage, setShowMessage] = useState(false);
  const [showMessageError, setShowMessageError] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    if (recaptchaRef?.current) {
      const token = await recaptchaRef?.current.executeAsync();
      setFormData((prevData) => ({ ...prevData, acToken: token }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validacija
    const newErrors = {};
    if (!formData.acEmail) {
      newErrors.acEmail = "Obavezno polje";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      if (isValid === false) {
        setErrors({ acEmail: "Obavezno polje" });
        setErrorMessage("greska 2");
        setShowMessage(false);
        setShowMessageError(true);
        setTimeout(() => {
          setShowMessageError(false);
        }, 3000);
      } else {
        if (formData.acToken)
          dispatch(fetchNewsletterFormSent(formData, showMessageContact));
      }
    } else {
      setErrorMessage("greska1");
      setShowMessage(false);
      setShowMessageError(true);
      setTimeout(() => {
        setShowMessageError(false);
      }, 3000);
    }
  };

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const modalCloseHandler = () => {
    toggleModal(false);
  };

  const showMessageContact = () => {
    // setShowMessage(true);
    toggleModal();
    setShowMessageError(false);

    setTimeout(() => {
      toggleModal();
    }, 60000);

    setFormData({
      acEmail: "",
    });
    getToken();
    setErrors(initialStateErrors);
  };

  useEffect(() => {
    // Provera da li je kolačić već prihvaćen
    const isCookieAccepted = localStorage.getItem("cookieAccepted");
    if (isCookieAccepted) {
      setCookieAccepted(true);
    }
  }, []);

  const acceptCookie = () => {
    // Postavljanje kolačića
    localStorage.setItem("cookieAccepted", true);
    setCookieAccepted(true);
  };

  return (
    <footer className="footer appear-animate">
      <div className={`cookie-container-block ${!cookieAccepted && "show"}`}>
        <div className="cookie-container-content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="cookie-body-content">
                  <div className="cookie-body-content-text">
                    <h4>KOLAČIĆI</h4>
                    <p>
                      Na ovoj stranici, koristimo kolačiće. Svrha kolačića je
                      poboljšanje Vašeg korisničkog iskustva te funkcionalnosti
                      usluge, za više informacija{" "}
                      <a
                        href="/documents/Kolacici.pdf"
                        target="_blank"
                        style={{ color: "#ff6206" }}
                      >
                        kliknite ovde
                      </a>
                      . Koristimo sledeće vrste kolačića: <br />
                      Obavezni kolačići koji osiguravaju pravilan rad stranice.
                    </p>
                  </div>
                  <div className="cookie-body-content-button">
                    <button
                      className="btn btn-primary btn-cart w-100"
                      onClick={acceptCookie}
                    >
                      <span>Prihvatam</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer-newsletter">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-5 col-lg-6">
              <div className="icon-box icon-box-side text-dark">
                <div className="icon-box-icon d-inline-flex">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ fontSize: "40px" }}
                  />
                </div>
                <div className="icon-box-content">
                  <h4 className="icon-box-title text-uppercase">
                    PRIJAVITE SE NA NAŠ NEWSLETTER
                  </h4>
                  <p className="ls-10">
                    Saznajte sve najnovije informacije o događajima, prodajama i
                    ponudama.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-9 mt-4 mt-lg-0">
              <form
                onSubmit={handleSubmit}
                className="input-wrapper input-wrapper-inline input-wrapper-rounded"
              >
                <ReCAPTCHA sitekey={key} size="invisible" ref={recaptchaRef} />
                <input
                  className={`form-control mr-2 bg-white border-no`}
                  type="email"
                  name="acEmail"
                  value={formData.acEmail}
                  placeholder={"Unesite vašu email adresu"}
                  onChange={handleInputChange}
                />
                {/* <input
                  type="email"
                  className="form-control mr-2 bg-white border-no"
                  name="email"
                  id="email"
                  placeholder="Unesite Vašu email adresu"
                /> */}
                <button className="btn btn-primary btn-rounded" type="submit">
                  Prijava <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-top">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="widget widget-about">
                <a className="logo-footer">
                  <img
                    src="https://up-it.rs/assets/img/logo/logo_dark.png"
                    alt="logo-footer"
                    width="144"
                    height="45"
                  />
                </a>
                <div className="widget-body">
                  <p className="widget-about-title">Kontakt telefoni</p>
                  <a href="tel:+38162208684" className="widget-about-call">
                    062/20-86-84
                  </a>
                  <a href="tel:+38162639796" className="widget-about-call">
                    062/63-97-96
                  </a>
                  {/* <p className="widget-about-desc mb-4">
                    Register now to get updates on pronot get up icons & coupons
                    ster now toon.
                  </p> */}
                  <div className="social-icons social-icons-colored">
                    <a
                      href="https://www.instagram.com/up_it_mld?utm_source=qr&igsh=MXFvYTAwbXRzdTkyNg=="
                      target="_blank"
                    >
                      <img src="/images/instagram.png" className="img-fluid" />
                    </a>
                    <a
                      href="https://www.facebook.com/UpItServis?mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <img src="/images/facebook.png" className="img-fluid" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="widget">
                <h3 className="widget-title">Brzi linkovi</h3>
                <ul className="widget-body">
                  <li>
                    <Link to="/izdvajamo-iz-ponude">Izdvajamo iz ponude</Link>
                  </li>
                  <li>
                    <Link to="/popularno">Popularno</Link>
                  </li>
                  <li>
                    <Link to="/mesecna-akcija">Mesečna akcija</Link>
                  </li>
                  <li>
                    <Link to="/preporucujemo">Preporučujemo</Link>
                  </li>
                  <li>
                    <Link to="/najprodavanije">Najprodavanije</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="widget">
                <h4 className="widget-title">O nama</h4>
                <ul className="widget-body">
                  <li>
                    <Link to="/kontakt">Kontakt</Link>
                  </li>
                  <li>
                    <a href="/images/uslovi-placanja.jpg" target="_blank">
                      Uslovi plaćanja
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="widget">
                <h4 className="widget-title">OPŠTE INFORMACIJE</h4>
                <ul className="widget-body">
                  <li>
                    <a
                      href="/documents/Izjava o odustanku od ugovora na daljkinu UP-IT.pdf"
                      target="_blank"
                    >
                      Izjava o odustanku od ugovora na daljkinu UP-IT
                    </a>
                  </li>
                  <li>
                    <a href="/documents/Kolacici.pdf" target="_blank">
                      Kolačići
                    </a>
                  </li>
                  <li>
                    <a
                      href="/documents/Opsti uslovi za kupovinu.pdf"
                      target="_blank"
                    >
                      Opšti uslovi za kupovinu
                    </a>
                  </li>
                  <li>
                    <a
                      href="/documents/Politika Privatnosti.pdf"
                      target="_blank"
                    >
                      Politika Privatnosti
                    </a>
                  </li>
                  <li>
                    <a
                      href="/documents/Saobraznost i reklamacije.pdf"
                      target="_blank"
                    >
                      Saobraznost i reklamacije
                    </a>
                  </li>
                  <li>
                    <a href="/documents/Ugovor o prodaji.pdf" target="_blank">
                      Ugovor o prodaji
                    </a>
                  </li>
                  <li>
                    <a
                      href="/documents/Zahtev za reklamaciju.pdf"
                      target="_blank"
                    >
                      Zahtev za reklamaciju
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-text-custom-special">
          Nastojimo da budemo što precizniji u opisu proizvoda, prikazu slika i
          samih cena, ali ne možemo garantovati da su sve informacije kompletne
          i bez grešaka. Svi artikli prikazani na sajtu su deo naše ponude i ne
          podrazumeva da su dostupni u svakom trenutku. Raspoloživost robe
          možete proveriti pozivanjem TEHNIČKE PODRŠKE ZA POTROŠAČE na
          062/20-86-84 ili 062/63-97-96
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-left">
            <p className="copyright">
              ©2024 www.up-it.shop.rs, Izrada{" "}
              <a
                href="https://www.developers.rs"
                target="_blank"
                style={{ color: "#ff6206" }}
              >
                Developers.rs
              </a>
              . Sva prava zadržana.
            </p>
          </div>
          <div className="footer-right"></div>
        </div>
      </div>
      {showModal && (
        <CustomModal
          backdrop={false}
          centered={true}
          footerContent={false}
          modalTitle={"Uspešno poslata poruka"}
          show={showModal}
          hideModal={modalCloseHandler}
        >
          <div>Hvala! Uspešno ste se prijavili na mailing listu.</div>
        </CustomModal>
      )}
    </footer>
  );
};

export default Footer;
