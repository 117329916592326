import React from "react";
import ListProductsItem from "./ListProductsItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Banner from "./Banner";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const ListProducts = ({ data, title }) => {
  return (
    <>
      <div className="title-link-wrapper after-none appear-animate mb-2 fadeIn appear-animation-visible">
        <h2 className="title title-special-block-custom pr-4">{title}</h2>
        <Link
          to={`/katalog/grupa/${title}?page=1`}
          className="btn btn-link btn-icon-right btn-show-all-special-custom"
        >
          Pogledajte našu ponudu
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
      </div>
      <div className="product-wrapper row cols-md-3 cols-sm-2 cols-1">
        {data?.map((item, key) => (
          <div className="product-wrap" key={key}>
            <ListProductsItem item={item} />
          </div>
        ))}
      </div>
    </>
  );
};

export default ListProducts;
