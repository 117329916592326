import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  fetchCatalogFilters,
  fetchPageNumberTotal,
  fetchProductsAllWithPagination,
} from "../actions/global";
import { useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "./Reusable/Loader";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const CatalogFiltersList = ({
  loadingFilterList,
  dataCatalogFiltersList,
  setPageCurrent,
  grupa,
  tip,
  perPage,
  page,
  orderCatalog,
  closeSidebarFilter,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  // Stanje za praćenje otvaranja/zatvaranja svake komponente
  const [isOpen, setIsOpen] = useState("");

  const handleToggle = (naziv) => {
    // Ažuriranje stanja za trenutnu komponentu
    setIsOpen((prevState) => ({
      ...prevState,
      [naziv]: !prevState[naziv],
    }));
  };

  const handleCheckboxChange = (event, naziv, vrednost) => {
    event.preventDefault();
    event.stopPropagation();
    const searchParams = new URLSearchParams(location.search);

    // Uvek postavljamo page na 1
    searchParams.set("page", "1");
    setPageCurrent();

    // Proveravamo da li je vrednost već prisutna u URL-u
    const values = searchParams.getAll(naziv);
    if (values.includes(vrednost)) {
      // Ako postoji, uklanjamo je iz URL-a
      const updatedValues = values.filter((val) => val !== vrednost);
      searchParams.delete(naziv);
      updatedValues.forEach((val) => searchParams.append(naziv, val));
    } else {
      // Ako ne postoji, dodajemo je u URL
      searchParams.append(naziv, vrednost);
    }

    navigate(`?${searchParams.toString()}`);
  };

  useEffect(() => {
    // Funkcija koja se izvršava nakon svake promene u URL-u
    // Pozivamo funkciju za dohvat proizvoda sa novim parametrima
    const params = new URLSearchParams(location.search);
    // Add a second foo parameter.
    const queryParams2 = {};
    params.forEach((value, key) => {
      if (key != "page") {
        if (!queryParams2[key]) {
          // Proveravamo da li queryParams[key] postoji, ako ne postoji, inicijalizujemo ga kao niz
          queryParams2[key] = [];
        }
        queryParams2[key].push(value); // Dodajemo vrednost u niz umesto postavljanja na određeni indeks
      }
    });

    const dataValue = {
      tip: tip,
      grupa: grupa,
      page: page,
      perPage: perPage,
      order: orderCatalog,
      queryParams2,
      // Dodajte ostale parametre koje želite proslediti za dohvat proizvoda
    };
    dispatch(fetchProductsAllWithPagination(dataValue)); //podaci se pozivaju za katalog stranu
    dispatch(fetchPageNumberTotal(dataValue)); //pozivamo ukupan broj stranica da kreiramo pagainaciju
  }, [location, dispatch, orderCatalog]); // Pratimo promene u location.search*/

  useEffect(() => {
    // Funkcija koja se izvršava nakon svake promene u URL-u
    // Pozivamo funkciju za dohvat proizvoda sa novim parametrima
    const params = new URLSearchParams(location.search);
    // Add a second foo parameter.
    const queryParams2 = {};
    params.forEach((value, key) => {
      if (key != "page") {
        if (!queryParams2[key]) {
          // Proveravamo da li queryParams[key] postoji, ako ne postoji, inicijalizujemo ga kao niz
          queryParams2[key] = [];
        }
        queryParams2[key].push(value); // Dodajemo vrednost u niz umesto postavljanja na određeni indeks
      }
    });

    const dataValue = {
      tip: tip,
      grupa: grupa,
      page: page,
      perPage: perPage,
      order: orderCatalog,
      queryParams2,
      // Dodajte ostale parametre koje želite proslediti za dohvat proizvoda
    };
    dispatch(fetchCatalogFilters(dataValue)); //vracamo filtere za grupu ili podgrupu
  }, [location.pathname, dispatch]); // Pratimo promene u location.search*/

  const functionResetFilters = () => {
    // Razdvajanje putanje na segmente
    const pathSegments = location.pathname.split("/");

    // Formiranje nove putanje sa ažuriranim parametrima
    const newPath = `/katalog/${pathSegments[2]}/${pathSegments[3]}?page=1`;

    // Navigacija na novu putanju
    navigate(newPath);
  };

  return (
    <>
      <div className="filter-actions">
        <label>Filteri:</label>
        <a
          onClick={() => functionResetFilters(1)}
          className="btn btn-dark btn-link filter-clean"
        >
          Obriši sve
        </a>
      </div>
      <div className="button-submit-filter-mobile ">
        <button
          className="btn btn-primary btn-rounded w-100"
          onClick={() => closeSidebarFilter()}
        >
          Primeni odabrane filtere
        </button>
      </div>
      {loadingFilterList ? (
        <Loader />
      ) : (
        <>
          {Object.keys(dataCatalogFiltersList).length > 0 ? (
            <>
              {Object.entries(dataCatalogFiltersList).map(
                ([naziv, vrednosti]) => (
                  <div className="widget widget-collapsible" key={naziv}>
                    <h3
                      className={`widget-title ${
                        isOpen[naziv] ? "collapsed" : ""
                      }`}
                      onClick={() => handleToggle(naziv)}
                    >
                      <span>{naziv}</span>
                      <span className="toggle-btn"></span>
                    </h3>
                    <ul
                      className={`widget-body  item-check mt-1 ${
                        isOpen[naziv] ? "collapsed" : ""
                      }`}
                    >
                      {vrednosti?.map((vrednost, index) => (
                        <li key={index} className="custom-title-name-filter">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={searchParams
                                  .getAll(naziv)
                                  .includes(vrednost)}
                                onChange={(event) => {
                                  event.preventDefault(); // Sprečava dalje širenje događaja
                                  handleCheckboxChange(event, naziv, vrednost);
                                }}
                              />
                            }
                            label={vrednost}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </>
          ) : (
            <div className="alert alert-icon alert-primary alert-bg alert-inline show-code-action">
              <h4 className="alert-title">
                <FontAwesomeIcon icon={faInfoCircle} />
              </h4>{" "}
              Ne postoje filteri
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CatalogFiltersList;
