import {
  getBannerList,
  getBlogAllListItems,
  getCatalogFilters,
  getCategories,
  getClientList,
  getPageNumberTotal,
  getProducts,
  getProductsCategoriesType,
  getProductsWithPagination,
  getSearchProducts,
  getSliderList,
  sentContactFormPath,
  sentNewsletterFormPath,
} from "../constants/apiRoutes";

import apiService from "../utils/configAxios";
import { globalActions } from "../reducers/globalActions";
import i18next from "i18next";

export const fetchContactFormSent = (
  dataValues,
  showMessageContact,
  navigate
) => {
  return (dispatch) => {
    return apiService
      .post(sentContactFormPath(), dataValues)
      .then((response) => {
        showMessageContact();
        console.log(response, "respo");
      })
      .catch((err) => {
        navigate("", { replace: true });
        console.log(err, "err");
      });
  };
};

export const fetchNewsletterFormSent = (dataValues, showMessageContact) => {
  return (dispatch) => {
    return apiService
      .post(sentNewsletterFormPath(), dataValues)
      .then((response) => {
        showMessageContact();
        console.log(response, "respo");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchBlogListItems = (id = 0) => {
  return (dispatch) => {
    return apiService
      .get(getBlogAllListItems(i18next.language, id))
      .then((response) => {
        dispatch(globalActions.fetchItemsListBlogAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchCategoriesList = () => {
  return (dispatch) => {
    return apiService
      .get(getCategories())
      .then((response) => {
        dispatch(globalActions.fetchItemsCategoriesAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchProductsHomeCategoryAll1 = (dataValue) => {
  return (dispatch) => {
    return apiService
      .post(getProducts(), dataValue)
      .then((response) => {
        dispatch(globalActions.fetchProductsAll1(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchProductsHomeCategoryAll2 = (dataValue) => {
  return (dispatch) => {
    return apiService
      .post(getProducts(), dataValue)
      .then((response) => {
        dispatch(globalActions.fetchProductsAll2(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchRelatedProductsAll = (dataValue) => {
  return (dispatch) => {
    return apiService
      .post(getProducts(), dataValue)
      .then((response) => {
        dispatch(globalActions.fetchRelatedProductsAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchProductsDetail = (dataValue) => {
  return (dispatch) => {
    dispatch(globalActions.actionStartProductDetail());
    return apiService
      .post(getProducts(), dataValue)
      .then((response) => {
        dispatch(globalActions.fetchDetailProduct(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchProductsAllWithPagination = (dataValue) => {
  return (dispatch) => {
    dispatch(globalActions.actionStartCatalog2());
    return apiService
      .post(getProductsWithPagination(), dataValue)
      .then((response) => {
        dispatch(globalActions.actionStartCatalogClear());
        dispatch(globalActions.fetchProductsAll(response.data.items));
        // dispatch(globalActions.dataCatalogFilters(response.data.filters));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchSearchProductsAll = (dataValue) => {
  return (dispatch) => {
    dispatch(globalActions.actionStartSearch());
    return apiService
      .post(getSearchProducts(), dataValue)
      .then((response) => {
        dispatch(globalActions.dataSearchProductsList(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchCilentsList = () => {
  return (dispatch) => {
    return apiService
      .get(getClientList())
      .then((response) => {
        dispatch(globalActions.dataClientList(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchSlidersList = () => {
  return (dispatch) => {
    return apiService
      .get(getSliderList())
      .then((response) => {
        dispatch(globalActions.dataSliderList(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchBannersList = () => {
  return (dispatch) => {
    return apiService
      .get(getBannerList())
      .then((response) => {
        dispatch(globalActions.dataBannerList(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchPageNumberTotal = (dataValue) => {
  return (dispatch) => {
    return apiService
      .post(getPageNumberTotal(), dataValue)
      .then((response) => {
        dispatch(globalActions.dataCatalogPagination(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchCatalogFilters = (dataValue) => {
  return (dispatch) => {
    dispatch(globalActions.actionStartCatalogFilter());
    return apiService
      .post(getCatalogFilters(), dataValue)
      .then((response) => {
        dispatch(globalActions.actionStartCatalogFilterClear());
        dispatch(globalActions.dataCatalogFilters(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchProductsCategoriesTypeAll = (type) => {
  return (dispatch) => {
    return apiService
      .get(getProductsCategoriesType(type))
      .then((response) => {
        if (type === "mesecnaAkcija") {
          dispatch(globalActions.fetchProductsMesecnaAkcija(response.data));
        } else if (type === "popularno") {
          dispatch(globalActions.fetchProductsPopularno(response.data));
        } else if (type === "izdvajamo") {
          dispatch(globalActions.fetchProductsIzdvajamoIzPonude(response.data));
        } else if (type === "preporucujemo") {
          dispatch(globalActions.fetchProductsPreporucujemo(response.data));
        } else if (type === "specijalnaAkcija") {
          dispatch(globalActions.fetchProductsSpecijalnaAkcija(response.data));
        } else if (type === "ponovoNaStanju") {
          dispatch(globalActions.fetchProductsPonovoNaStanju(response.data));
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};
